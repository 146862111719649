import React from "react";
import Main from "../static-components/html/Main";
import LegalContainer from "../containers/shared/LegalContainer";

export default function LegalPage(){
    return (
        <Main title="Terms &amp; Conditions">
            <LegalContainer />
        </Main>
    );
}